.levels {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: center;
  width: min(1200px, 85%);
  margin: 30px 0px;
}

.levelrank {
  width: 350px;
}

.rank {
  width: 100%;
  font-size: 30px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}

.level {
  background-color: #363636;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  padding: 4px 8px;
  user-select: none;
}
.nameprogresscontainer {
  overflow-y: auto;
  max-height: 350px;
}

.nameprogress {
  display: flex;
  background-color: #484848;
}

/* .levels-heading {
  position: sticky;
  top: 0;
} */
.progressypoo {
  width: 35%;
  padding: 4px 8px;
  text-align: left;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progressfart {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progressinput {
  width: 100%;
  height: 100%;
  font-size: 16px;
}

.level.completed,
.nameprogress button.completed {
  background-color: rgb(30, 168, 49);
}

.level.doing,
.nameprogress button.doing {
  background-color: rgb(206, 203, 43);
}
.toast {
  position: fixed;
  bottom: 0;
  margin-bottom: 20px;
  width: max(12%, 200px);
  height: 50px;
  font-size: max(1.5vw, 24px);
  background-color: red;
  text-align: center;
  line-height: 40px;
  border: none;
  font-family: "Readex Pro", sans-serif;
  color: white;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.toast:hover {
  background-color: rgb(225, 0, 0);
}

.toast:active {
  background-color: rgb(200, 0, 0);
}

.nameprogress button {
  border: 0;
  background-color: #363636;
  color: white;
  font-size: 16px;
  cursor: pointer;
  padding: 0 8px;
}
.copytoastcontainer > div {
  display: none !important;
}
.copytoastcontainer > div:nth-child(-n + 3) {
  display: flex !important;
}
.norank {
  border: 4px solid transparent;
}
.rankachieved {
  border: 4px solid gold;
}
.plusrank {
  border: 4px solid black;
}

.plusrank.Legend {
  border: 4px solid white;
}
