.footer {
  width: 100%;
  background: linear-gradient(to top, #101010 10%, transparent);
  /* height: 150px; */
  text-align: center;
  /* display: flex; */
  flex-direction: column;
  padding-top: 4px;
  padding-bottom: 24px;
}

p {
  margin: 0;
  padding: 4px 8px;
}
