.header {
  width: 100%;
  height: 50px;
  font-size: 30px;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  position: sticky;
  top: 0;
  transition: filter 0.3s ease-in-out, background 0.3s ease-in-out;
}

.header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, #00000050 25%, transparent);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: -1;
}
.headershadow::before {
  opacity: 1;
}

.header-content {
  text-align: center;
  margin-left: 24px;
}

.header-text {
  display: flex;
  font-size: min(4vw, 80%);
  align-items: center;
  padding: 4px 8px;
  user-select: none;
  cursor: pointer;
}

.info {
  padding: 24px;
  cursor: pointer;
  font-size: min(3vw, 70%);
}

.popup {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #444;
  padding: 20px;
  z-index: 1000;
  height: 80vh;
  width: 60vh;
  box-shadow: 0px 0px 100px 5px rgba(0, 0, 0, 0.75);
}

.popupheading {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.closeicon {
  cursor: pointer;
  font-size: 36px;
}

h3 {
  margin: 0;
}

.popupp {
  font-size: 18px;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popupp .bronze {
  color: #dd7e6b;
}
.popupp .silver {
  color: #b7b7b7;
}
.popupp .gold {
  color: #f1c232;
}
.popupp .emerald {
  color: #6aa84f;
}
.popupp .ruby {
  color: #cc0000;
}
.popupp .diamond {
  color: #3d85c6;
}
.popupp .amethyst {
  color: #ff00ff;
}
.popupp .legend {
  color: #000000;
}
.popupp .bonus {
  color: #b4a7d6;
}
.infoinfo {
  height: 100%;
  overflow-y: scroll;
  padding: 0 24px;
}

.nav {
  display: flex;
  gap: 12px;
  margin-left: 24px;
}

.nav button {
  border: 0;
  font-family: "Readex Pro", sans-serif;
  font-size: min(3vw, 70%);
  padding: 4px 32px;
  cursor: pointer;
  background-color: #ffffff00;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: min(110px, 8vw);
}

.nav button:hover {
  background-color: #ffffff20;
}

.nav button:active {
  background-color: #ffffff40;
}
.dropdowncontainer {
  display: flex;
  flex-direction: column;
}

.dropdown {
  display: none;
  position: absolute;
  margin-top: 34px;
}
.nav .home:hover + .dropdown {
  display: block;
}

.dropdown:hover {
  display: block;
}
