:root {
  --toastify-toast-bd-radius: 0 !important;
}

body {
  font-family: "Readex Pro", sans-serif;
  background-color: #242424;
  color: white;
  margin: 0 auto;
  padding: 0;
}

input {
  background-color: #00000000;
  border: 0;
  outline: none;
  color: white;
  font-family: "Readex Pro", sans-serif;
  font-size: 24px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

.levelsprogress {
  display: flex;
  justify-content: center;
}

/* ===== Scrollbar CSS ===== */

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 16px;
}

*::-webkit-scrollbar-track {
  background: #3f3f3f00;
}

*::-webkit-scrollbar-thumb {
  background-color: #7a7a7a;
  border: 3px solid #ffffff00;
}
